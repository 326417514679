import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';

import {GeolocationErrorsList} from '@controls/location-autocomplete/models/geolocation.model';
import {LocationDto, LocationLatLngDto} from '@models/location';

import {DirectoriesService} from '../directories';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {

  constructor(
    private readonly directoriesService: DirectoriesService,
  ) {
  }

  getGeolocationAddress(): Observable<LocationDto> {
    return this.getGeolocationPosition().pipe(
      switchMap(position => {
        const {longitude, latitude} = position.coords;
        return this.getLocationAddress({lng: longitude, lat: latitude}).pipe(
          map(address => ({address, lat: latitude, lng: longitude})),
        );
      }),
      shareReplay(1),
    );
  }

  getGeolocationPosition(options: PositionOptions = {
    timeout: 5000,
  }): Observable<GeolocationPosition> {
    return new Observable<GeolocationPosition>(subscriber => {
      const geolocation = navigator.geolocation;
      const errKey = 'errors';
      if (!geolocation) {
        subscriber.error(`${errKey}.geolocationSupport`);
        subscriber.complete();
        return;
      }
      geolocation.getCurrentPosition(
        position => subscriber.next(position),
        error => {
          if (error.code === GeolocationErrorsList.PERMISSION_DENIED) {
            subscriber.error(`${errKey}.geolocationAccess`);
            return;
          }
          if (error.code === GeolocationErrorsList.POSITION_UNAVAILABLE) {
            subscriber.error(`${errKey}.geolocationPosition`);
            return;
          }
          if (error.code === GeolocationErrorsList.TIMEOUT) {
            subscriber.error(`${errKey}.geolocationTimeout`);
            return;
          }
          subscriber.error(`${errKey}.geolocationError`);
        },
        options,
      );
    });
  }

  getLocationAddress(latLng: LocationLatLngDto): Observable<string> {
    return this.directoriesService.getGeolocationByCoordinates(latLng)
      .pipe(
        map(res => res.data.address),
      );
  }
}
